import React from 'react'
import './RecordTimer.css'

export default function RecordTimer(props) {
  const { time } = props

  function normalizeM(n) {
    let msStr = n.toString()
    if (msStr.length === 1) {
      msStr += '0'
    } else if (msStr.length > 2) {
      msStr = msStr.substr(0, 2)
    }

    return msStr
  }

  function normalizeS(n) {
    let msStr = n.toString()
    if (msStr.length === 1) {
      msStr = '0' + msStr
    }

    return msStr
  }

  function msToTime(s) {
    let ms = s % 1000
    s = (s - ms) / 1000
    let secs = s % 60
    s = (s - secs) / 60
    let mins = s % 60
    let hrs = (s - mins) / 60

    let result = mins + ':' + normalizeS(secs) + ',' + normalizeM(ms)
    if (hrs > 0) {
      result = hrs + ':' + result
    }

    return result
  }

  if (!time) return null

  return (
    <div className="record-timer">
      {msToTime(time)}
      <div className="record-timer-icon" />
    </div>
  )
}
