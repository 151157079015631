import { ReduceOne, Star } from '@icon-park/react'
import React from 'react'

export default function Tips() {
  return (
    <div>
      <p>请使用Edge、Chrome、Safari浏览器 或微信内访问 获得更好的兼容性体验</p>
      <p>
        1. 左滑 私聊列表项 可<span style={{ fontWeight: 'bold' }}>“收藏、清空记录、删除、拉黑”</span>相应的聊天
      </p>
      <p>
        2. 删除聊天，将清空双方历史聊天记录，<span style={{ fontWeight: 'bold' }}>并删除双方聊天入口</span>
      </p>
      {/* <p>4. 右上角 “编辑 - 创建群聊”，可创建群聊</p> */}
      <p>
        3. 右上角 “功能点 - 清空私聊”，<span style={{ fontWeight: 'bold' }}>将删除所有未收藏的私聊</span>
        ，已收藏的私聊需要手动删除
      </p>
      <p>
        4. <Star style={{ marginRight: 5 }} theme="filled" size="14" fill="#F5A623" />
        表示已收藏该聊天，
        <ReduceOne style={{ marginRight: 5 }} theme="filled" size="14" fill="#f52323" />
        表示已拉黑该用户，
      </p>
      <p>
        5. 长按（电脑端右键）聊天中的消息，支持<span style={{ fontWeight: 'bold' }}>复制、 撤回</span>
        消息。夸克、UC浏览器无法触发操作，请更换浏览器
      </p>
    </div>
  )
}

// <div>
//       <p>请使用Edge、Chrome、Safari浏览器 或微信内访问 获得更好的兼容性体验</p>
//       <p>
//         1. 左滑 群聊列表项 可<span style={{ fontWeight: 'bold' }}>“解散群组、退出群组”</span>
//       </p>
//       <p>
//         2. 左滑 私聊列表项 可<span style={{ fontWeight: 'bold' }}>“收藏、清空记录、删除、拉黑”</span>相应的聊天
//       </p>
//       <p>
//         3. 删除聊天，将清空双方历史聊天记录，<span style={{ fontWeight: 'bold' }}>并删除双方聊天入口</span>
//       </p>
//       {/* <p>4. 右上角 “编辑 - 创建群聊”，可创建群聊</p> */}
//       <p>
//         4. 右上角 “编辑 - 清空私聊”，<span style={{ fontWeight: 'bold' }}>将删除所有未收藏的私聊</span>
//         ，已收藏的私聊需要手动删除
//       </p>
//       <p>
//         5. <Star style={{ marginRight: 5 }} theme="filled" size="14" fill="#F5A623" />
//         表示已收藏该聊天，
//         <ReduceOne style={{ marginRight: 5 }} theme="filled" size="14" fill="#f52323" />
//         表示已拉黑该用户，
//       </p>
//       <p>
//         6. 长按（电脑端右键）聊天中的消息，支持<span style={{ fontWeight: 'bold' }}>复制、 撤回</span>
//         消息。夸克、UC浏览器无法触发操作，请更换浏览器
//       </p>
//     </div>
