import Message from '@/components/message/Message'
import { InfiniteScroll, ImageViewer, ActionSheet, Toast, Button } from 'antd-mobile'
import { useRef, useEffect, useState, useMemo } from 'react'
import { DownC } from '@icon-park/react'
import UnreadSeparator from '@/components/message/UnreadSeparator'
import { wsSetMsgsIsRead, wsDeleteMsg, wsSetMsgMeta } from '@/services/websocket'
import copy from 'copy-to-clipboard'
import './MessageList.css'
import cookie from 'react-cookies'

const MessageList = function MessageList(props) {
  const {
    uid,
    user,
    updateUserInfoCache,
    currChat,
    itemMap,
    onLoadMore,
    innerRef,
    msgMapQuery,
    chatid,
    toUid,
    isTyping,
    receiveNewMsg,
    deleteMsg,
    setMsgMeta,
    isGroupChat,
    userMap,
  } = props
  const is_debug = cookie.load('is_debug')
  const hasMore = useMemo(() => {
    if (msgMapQuery) {
      return msgMapQuery.hasMore
    }
    return false
  }, [msgMapQuery])

  const [firstUnreadMessageId, setFirstUnreadMessageId] = useState(null)
  const [isTop, setIsTop] = useState(false)
  const [isBottom, setIsBottom] = useState(false)
  const [scrollPos, setScrollPos] = useState(null)

  const listRef = useRef(null)
  const firstUnreadRef = useRef(null)
  let messageMap = {}

  function handleShowImage(messageId) {
    return () => {
      imageMsgs.forEach((e, i) => {
        if (e.id === messageId) {
          ImageViewer.Multi.show({
            images: imageMsgs.map((msg) => msg.msgContent),
            defaultIndex: i,
          })
          return
        }
      })
    }
  }

  const getScrollPos = () => {
    const list = listRef.current
    if (!list) return
    const { scrollTop, scrollHeight, offsetHeight } = list
    return { scrollTop, scrollHeight, offsetHeight }
  }

  const getIsBottom = () => {
    const { scrollTop, scrollHeight, offsetHeight } = getScrollPos()
    if (scrollHeight - offsetHeight === scrollTop) {
      return true
    }
    return false
  }

  const getIsTop = () => {
    const { scrollTop } = getScrollPos()
    if (scrollTop === 0) {
      return true
    }
    return false
  }

  const handleScroll = () => {
    setIsTop(getIsTop())
    setIsBottom(getIsBottom())
    setScrollPos(null)
  }

  const scrollToBottom = () => {
    const list = listRef.current
    if (!list) return
    const { scrollTop, scrollHeight, offsetHeight } = list
    const nextScrollTop = scrollHeight - offsetHeight
    if (nextScrollTop !== scrollTop) {
      list.scrollTop = nextScrollTop
    }
    setIsBottom(true)
  }

  const scrollToUnread = () => {
    if (firstUnreadRef && firstUnreadRef.current) {
      const list = listRef.current
      list.scrollTop = firstUnreadRef.current.offsetTop
    } else {
      scrollToBottom()
    }
  }

  const loadMore = async () => {
    setScrollPos(getScrollPos())
    setIsTop(false)
    return await onLoadMore()
  }

  useEffect(() => {
    async function fetchData() {
      const newMessages = await loadMore()
      if (!isGroupChat) {
        setFirstUnreadMessageId(getFirstUnreadMessageId(newMessages))
        setTimeout(() => {
          scrollToUnread()
        }, 30)
      } else {
        setTimeout(() => {
          scrollToBottom()
        }, 30)
      }
    }
    // 当前消息列表有消息，不获取
    if (!msgMapQuery) {
      fetchData()
    } else {
      scrollToUnread()
    }
  }, [])

  useEffect(() => {
    // 如果不是底部的话，不可以滑到底部，提示有新消息
    if (isBottom) {
      scrollToBottom()
    } else if (scrollPos) {
      const list = listRef.current
      const scrollBottom = scrollPos.scrollHeight - scrollPos.scrollTop
      list.scrollTop = list.scrollHeight - scrollBottom
    }
  })

  useEffect(() => {
    if (!isGroupChat) {
      setTimeout(() => {
        setMessageIsRead()
      }, 200)
    }
  }, [Object.keys(itemMap || {}).length])

  function isInView(dom) {
    if (!dom) return false
    const list = listRef.current
    const { top, bottom } = list.getBoundingClientRect()
    const rect = dom.getBoundingClientRect()
    // if (rect.bottom < bottom && rect.top > top) {
    // 露出一半就算
    if (rect.bottom - rect.height / 2 < bottom) {
      return true
    }
    return false
  }

  function setMessageIsRead() {
    let messageIds = []
    Object.keys(messageMap || {}).forEach((messageId) => {
      const message = messageMap[messageId]
      if (!message.isOutgoing && message.msgStatus === 'isSend') {
        if (isInView(message.dom)) {
          messageIds.push(messageId)
        }
      }
    })
    // console.log('wsSetMsgsIsRead', messageIds)
    if (messageIds.length && !is_debug) {
      wsSetMsgsIsRead(uid, toUid, chatid, messageIds)
    }
  }

  function showMessageDate(message, prevMessage) {
    if (!prevMessage) return true
    const date = new Date(message.ts)
    const prevDate = prevMessage ? new Date(prevMessage.ts) : date
    if (
      date.getFullYear() !== prevDate.getFullYear() ||
      date.getMonth() !== prevDate.getMonth() ||
      date.getDate() !== prevDate.getDate()
    )
      return true
    return false
  }

  function showSpiltSpace(message, prevMessage) {
    if (!prevMessage) return false
    const date = new Date(message.ts)
    const prevDate = prevMessage ? new Date(prevMessage.ts) : date
    // 同一天消息超过1小时给间隔
    if (date.getTime() - prevDate.getTime() > 3600 * 1000) {
      return true
    }
    return false
  }

  function getFirstUnreadMessageId(newMessages) {
    if (!newMessages || newMessages.length === 0) {
      return null
    }
    // 补充 isOutgoing, showDate
    const tmpMap = {}
    newMessages.forEach((e) => {
      tmpMap[e['id']] = e
    })
    const allMsgs = Object.assign({}, itemMap, tmpMap)
    let messages = []
    Object.keys(allMsgs || {}).map((messageId) => {
      const item = Object.assign({}, allMsgs[messageId])
      messages.push(item)
    })
    // 按时间排序
    messages.sort((a, b) => {
      return a.ts > b.ts ? 1 : -1
    })
    //
    for (let i = 0; i < messages.length; i++) {
      const e = messages[i]
      if (e.uid !== uid && e.uid !== null && (e.msgStatus === 'isSend' || e.is_read === false)) {
        return e.id
      }
    }
    return null
  }

  const messages = useMemo(() => {
    return getMessages()
  }, [itemMap, getMessages()])

  const imageMsgs = useMemo(() => {
    const ret = []
    messages.forEach((msg) => {
      if (msg.msgType === 'image') {
        ret.push(msg)
      }
    })
    return ret
  }, [messages])

  function getMessages() {
    // 补充 isOutgoing, showDate
    const messages = []
    Object.keys(itemMap || {}).map((messageId) => {
      const item = Object.assign({}, itemMap[messageId])
      item.isOutgoing = item.uid === uid ? true : false
      if (user.is_vip || is_debug) {
        if (['image', 'video'].indexOf(item.msgType) !== -1) {
          item.msgContent = item.msgContent.replace('_d_.jpg', '')
          item.msgContent = item.msgContent.replace('_d_.mp4', '')
        }
      }
      messages.push(item)
    })
    // 按时间排序
    messages.sort((a, b) => {
      return a.ts > b.ts ? 1 : -1
    })
    // 补充每天第一条消息信息
    messages.forEach((e, i) => {
      if (e.uid !== null) {
        e.isShowUser = true
        let prevMessage = i > 0 ? messages[i - 1] : null
        if (prevMessage && prevMessage.msgType === 'system') {
          prevMessage = null
        }
        e.showDate = showMessageDate(e, prevMessage)
        // 判断当前消息和上条消息是否是同一用户
        if (prevMessage && prevMessage.uid === e.uid) {
          e.isShowUser = false
        }
      }
    })
    return messages
  }

  const setMsgRef = (message) => {
    return (dom) => {
      if (dom) {
        messageMap[message.id] = { ...message, dom: dom }
      }
    }
  }

  function handleSetMsgMeta(messageId, metaData) {
    setMsgMeta(chatid, messageId, metaData)
    wsSetMsgMeta(toUid, chatid, messageId, metaData)
  }

  function handleMsgOpt(message, isGroupChat) {
    return (e) => {
      if (e.key === 'copy') {
        if (message.msgType === 'text' || message.msgType === 'group_invite') {
          copy(message.msgContent)
          Toast.show('消息已复制到剪切板')
        } else {
          Toast.show({ icon: 'fail', content: '只支持复制文本消息' })
        }
      } else if (e.key === 'delete') {
        if (message.msgType === 'dice') {
          Toast.show({ icon: 'fail', content: '骰子无法撤回' })
          return
        }
        wsDeleteMsg(uid, message.uid, message.id, chatid, toUid, isGroupChat)
        deleteMsg(chatid, message.id)
        Toast.show('消息已撤回')
      }
    }
  }

  return (
    <div style={{ flex: 1 }} className="messages-list messages-list-selection-active">
      <div ref={listRef} className="messages-list-wrapper" onScroll={handleScroll}>
        <div className="messages-list-top" />

        {isTop && <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />}

        <div className="messages-list-items">
          {messages.map((message) => {
            return (
              <div key={message.id} ref={setMsgRef(message)}>
                {!isGroupChat && firstUnreadMessageId === message.id && (
                  <div ref={firstUnreadRef}>
                    <UnreadSeparator />
                  </div>
                )}

                <Message
                  messageId={message.id}
                  updateUserInfoCache={updateUserInfoCache}
                  {...message}
                  handleShowImage={handleShowImage}
                  currUid={uid}
                  handleMsgOpt={handleMsgOpt}
                  handleSetMsgMeta={handleSetMsgMeta}
                  isGroupChat={isGroupChat}
                  userMap={userMap}
                  currChat={currChat}
                />
              </div>
            )
          })}
        </div>
        {!isBottom && <div ref={innerRef} />}

        <div
          style={{
            fontSize: 10,
            color: 'var(--theme-text-color)',
            height: '21px',
          }}>
          <span
            style={{
              marginLeft: 8,
            }}>
            {isTyping ? '对方正在输入...' : ''}
          </span>

          <span
            style={{
              float: 'right',
              marginRight: 12,
              color: 'var(--adm-color-danger)',
            }}>
            {receiveNewMsg ? '其它对话收到新消息' : ''}
          </span>
        </div>
      </div>

      {!isBottom && (
        <div onClick={scrollToBottom} style={{ position: 'absolute', bottom: 0, right: 7 }}>
          {/* <Badge content={firstUnreadDom ? Badge.dot : null} style={{ '--right': '10%', '--top': '10%' }}>
            <DownC style={{ float: 'right' }} theme="filled" size="32" fill="#acacac" />
          </Badge> */}
          <DownC style={{ float: 'right' }} theme="filled" size="32" fill="#acacac" />
        </div>
      )}
    </div>
  )
}

export default MessageList
