import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Image, Input, Modal, Radio, Space, Toast, Dialog } from 'antd-mobile'
import { newUserApi } from '@/services/user'
import './Register.css'
import { getVerifyCodeApi } from '@/services/base'
import TermOfService from '@/pages/TermOfService'
const queryString = require('query-string')

export default function Register(props) {
  const parsed = queryString.parse(window.location.search)

  const { visible, setVisible, initSystem } = props
  const [verifyCodeImg, setVerifyCodeImg] = useState('')
  const verifyCodeIdRef = useRef(null)

  async function getVerifyCode() {
    const { data, code, msg } = await getVerifyCodeApi()
    if (code !== 200) {
      Toast.show({ icon: 'fail', content: msg })
      return
    }
    setVerifyCodeImg(data.image)
    verifyCodeIdRef.current = data.id
  }

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        getVerifyCode()
      }, 100)
    }
  }, [visible])

  const handleRegister = async (values) => {
    const { gender, age, verifyCode, isAgree } = values
    if (!gender) {
      Toast.show({ icon: 'fail', content: '请选择性别' })
      return
    }
    if (!age) {
      Toast.show({ icon: 'fail', content: '请输入年龄' })
      return
    }
    if (!verifyCode) {
      Toast.show({ icon: 'fail', content: '请输入验证码' })
      return
    }
    if (!isAgree) {
      Toast.show({ icon: 'fail', content: '请先同意服务条款' })
      return
    }
    const bindToken = parsed.bind
    const { msg, data, code } = await newUserApi(gender, Number(age), verifyCode, verifyCodeIdRef.current, bindToken)
    if (code !== 200) {
      Toast.show({ icon: 'fail', content: msg })
      return
    }
    let toastMsg = '创建成功'
    if (bindToken) {
      if (data.user_type === 'try') {
        toastMsg = '创建成功，激活失败。' + data.bindMsg || ''
      } else {
        toastMsg = '创建成功，已激活为正式用户'
      }
    }
    Toast.show({ icon: 'success', content: toastMsg })
    setTimeout(() => {
      initSystem(data)
      setVisible(false)
    }, 2000)
  }

  function handleShowTOS() {
    Modal.show({
      title: '服务条款',
      content: <TermOfService hideTitle={true} />,
      closeOnMaskClick: true,
      showCloseButton: true,
    })
  }

  return (
    <>
      <Modal
        title="填写信息"
        visible={visible}
        showCloseButton
        closeOnMaskClick
        onClose={() => {
          setVisible(false)
        }}
        content={
          <div>
            <div style={{ fontSize: 10, color: 'var(--adm-color-weak)', textAlign: 'center' }}>
              <div>若已有账户，请在设置页 账号或绑定激活 直接登录</div>
              <div style={{ color: 'red' }}>性别设置好后无法修改，请谨慎选择</div>
            </div>
            <Form
              className="register-wrapper"
              layout="horizontal"
              mode="card"
              initialValues={{ username: '', password: '' }}
              onFinish={handleRegister}
              footer={
                <Button block type="submit" color="primary" size="large">
                  开始聊天
                </Button>
              }>
              <Form.Item name="gender" label="性别">
                <Radio.Group>
                  <Space direction="horizontal" style={{ '--gap': '30px' }}>
                    <Radio value="male">男</Radio>
                    <Radio value="female">女</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="age"
                label="年龄"
                rules={[
                  {
                    max: 70,
                    min: 18,
                    type: 'number',
                    message: '仅服务18-70之间的人群',
                    transform: (value) => {
                      return Number(value)
                    },
                  },
                ]}>
                <Input placeholder="输入年龄" />
              </Form.Item>
              <Form.Item name="verifyCode" label="验证码">
                <Input placeholder="输入下图验证码" />
              </Form.Item>
              <Form.Item label="图片">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Image alt="img" src={'data:image/png;base64,' + verifyCodeImg} height={30} />
                  {/* <span style={{ userSelect: 'none', fontSize: '20px' }}>{verifyCode}</span> */}
                  <Button
                    style={{ userSelect: 'none', fontSize: '10px', padding: '0 0 0 3px', width: '46px' }}
                    color="primary"
                    fill="none"
                    size="mini"
                    onClick={getVerifyCode}>
                    换一个
                  </Button>
                </div>
              </Form.Item>
              <Form.Item name="isAgree">
                <Radio
                  style={{
                    '--icon-size': '18px',
                    '--font-size': '14px',
                    '--gap': '6px',
                  }}>
                  请阅读并承诺遵守&nbsp;
                  {/* <a href="/tos" target="_blank" rel="noreferrer">
                    服务条款
                  </a> */}
                  <Button
                    style={{ padding: 0, fontSize: 'var(--font-size)' }}
                    color="primary"
                    fill="none"
                    size="small"
                    onClick={handleShowTOS}>
                    服务条款
                  </Button>
                </Radio>
              </Form.Item>
            </Form>
          </div>
        }
      />
    </>
  )
}

// 提示用户注册账号
// let ex = null
// const time = data.last_login_time || data.create_time
// if (isSafari()) {
//   ex = new Date(new Date(time.replace(/-/g, '/').split('.')[0]).getTime() + 14 * 24 * 3600 * 1000)
// } else {
//   ex = new Date(new Date(time).getTime() + 14 * 24 * 3600 * 1000)
// }
// if (!data.account && !cookie.load('showTips')) {
//   Dialog.alert({
//     title: '提示',
//     maskStyle: { opacity: 0.5 },
//     content: (
//       <div style={{ textAlign: 'center' }}>
//         <span>当前游客账号将在</span>
//         <span style={{ fontWeight: 'bold', color: 'red' }}>{dayDiff(ex, new Date())}</span>
//         <span>天后被清理，请尽快注册账号保存记录！</span>
//         <div style={{ marginTop: 10, fontWeight: 'bold' }}>
//           注册账号
//           <span style={{ color: 'red' }}>无需</span>
//           手机号等任何个人信息
//         </div>
//       </div>
//     ),
//     closeOnMaskClick: false,
//   })
//   let cookieTime = new Date(new Date().getTime() + 24 * 3600 * 1000)
//   cookie.save('showTips', 1, { expires: cookieTime })
// }
