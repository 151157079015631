import { HashRouter, BrowserRouter } from 'react-router-dom'
import Layout from '@/layout/Layout'
import { Provider } from 'react-redux'
import store from './redux/store'
import '@/utils/dateFormat'


function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Layout />
      </BrowserRouter>
    </Provider>
  )
}

export default App
