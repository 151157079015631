import { Popover, Dialog, Badge, Toast, NoticeBar, Modal, List, SwipeAction, Image } from 'antd-mobile'
import { LeftSmall } from '@icon-park/react'
import UserProfile from '@/components/user-profile/UserProfile'
import { getUserBaseInfoApi } from '@/services/user'
import { useEffect, useRef, useState } from 'react'
import Avatar from 'react-avatar'
import { Avatar as AvatarAntD } from 'antd-mobile'
import { PubSub } from 'pubsub-js'

import './DialogHeader.css'
import GroupChatEditor from '../group-chat/GroupChatEditor'
import { getGroupChatUsersInfoApi, deleteGroupChatMemberApi } from '@/services/chat'
import { wsLeaveRoom } from '@/services/websocket'

export default function DialogHeader(props) {
  const {
    user,
    chatInfo,
    avatarUrl,
    setGroupUids,
    memberCount,
    onlineMemberCount,
    chatTitle,
    isGroupChat,
    chatStatus,
    onClickBack,
    chatUid,
    toUid,
    showUserInfo,
    setUserInfoCache,
    updateChat,
    updateUserInfoCache,
    chatid,
    allUnreadMsgCount,
    isShowRematch,
    setUserMapMany,
  } = props

  let newMsgRef = useRef(null)
  const dialogRef = useRef(null)
  const [editGroupChatVisible, setEditGroupChatVisible] = useState(false)
  const [manageUserVisible, setManageUserVisible] = useState(false)
  const [members, setMembers] = useState({})

  const [isShowMembers, setIsShowMembers] = useState(false)

  useEffect(() => {
    if (showUserInfo) {
      openProfile('clickAvatar', isShowRematch)()
    }
    if (isGroupChat) {
      if (chatid === 'a7973381807c28c19ffccb3a73779c7a') {
        if (user.id === '635bb18fe70cf88f55e04368') {
          setIsShowMembers(true)
        } else {
          setIsShowMembers(false)
        }
      } else setIsShowMembers(true)
    }
  }, [])

  useEffect(() => {
    if (allUnreadMsgCount > 0) {
      newMsgRef.current.show()
      setTimeout(() => {
        newMsgRef.current.hide()
      }, 1000)
    }
  }, [allUnreadMsgCount])

  const tailMap = {
    online: {
      text: '在线',
      color: '#38b503',
    },
    offline: {
      text: '离线',
      color: '#a6a6a6',
    },
    none: {
      text: '',
      color: '#fff',
    },
    typing: {
      text: '正在输入..',
      color: '#000000',
    },
  }

  function quitChat(chatid) {
    return () => {
      if (dialogRef.current) {
        dialogRef.current.close()
      }
      onClickBack()
      // 发消息，删除聊天
      PubSub.publish('message', { func: 'delete_chat_manual', data: { chatid: chatid } })
    }
  }

  function openProfile(clickPos, showRematch = false) {
    return async () => {
      if (!isGroupChat) {
        if (!toUid) {
          Toast.show({ icon: 'fail', content: '数据加载异常，请刷新页面后重试' })
          return
        }
        const { data, code } = await getUserBaseInfoApi(toUid, 'openProfile')
        if (code !== 200) return
        console.log('uid:', data.id)
        setUserInfoCache(data)
        dialogRef.current = Dialog.show({
          closeOnMaskClick: true,
          content: (
            <UserProfile
              user={data}
              updateUserInfoCache={updateUserInfoCache}
              chatid={chatid}
              showOpt={true}
              quitChat={quitChat}
              isShowRematch={showRematch}
            />
          ),
        })
      } else {
        if (clickPos === 'clickAvatar') {
          setEditGroupChatVisible(true)
        } else {
          if (chatid === 'a7973381807c28c19ffccb3a73779c7a' && user.id !== '635bb18fe70cf88f55e04368') {
            Toast.show({ icon: 'fail', content: '聊天大厅管理员才可查看' })
            return
          }
          const { code, data, msg } = await getGroupChatUsersInfoApi(chatid)
          if (code !== 200) {
            Toast.show({ icon: 'fail', content: msg })
          }
          setUserMapMany(data)
          let membersMap = {}
          const onlineUsers = []
          const onfflineUsers = []
          data.forEach((e) => {
            if (e.id === chatUid) membersMap[e.id] = e
            else if (e.status === 'online') onlineUsers.push(e)
            else if (e.status === 'offline') onfflineUsers.push(e)
          })

          onlineUsers.forEach((e) => {
            membersMap[e.id] = e
            setGroupUids(chatid, [e.id], 'add', 'online')
            setGroupUids(chatid, [e.id], 'add', 'member')
          })
          onfflineUsers.forEach((e) => {
            membersMap[e.id] = e
            setGroupUids(chatid, [e.id], 'add', 'member')
          })
          setMembers(membersMap)
          setManageUserVisible(true)
        }
      }
    }
  }

  function handleManageMember(member) {
    return async (action, e) => {
      if (action.key === 'delete') {
        if (member.id === chatUid) {
          Toast.show({ icon: 'fail', content: '无法删除群主，若要解散群聊，请在聊天列表操作' })
          return
        }
        const { code, msg } = await deleteGroupChatMemberApi(chatid, member.id)
        if (code !== 200) {
          Toast.show({ icon: 'fail', content: msg })
          return
        }
        // 发消息用户离开群组
        wsLeaveRoom(member.id, chatid)
        // membersMap 中删除
        const membersMap = Object.assign({}, members)
        delete membersMap[member.id]
        setMembers(membersMap)
        Toast.show('已将该用户从群聊删除')
      }
    }
  }

  return (
    <div className="header-wrapper">
      <Modal
        closeOnMaskClick={true}
        title={user.id === chatUid ? '编辑群聊' : '查看群聊'}
        visible={editGroupChatVisible}
        onClose={() => {
          setEditGroupChatVisible(false)
        }}
        content={
          <GroupChatEditor
            panelType="edit"
            chatid={chatid}
            isManager={user.id === chatUid}
            updateChat={updateChat}
            onFinish={() => {
              setEditGroupChatVisible(false)
            }}
          />
        }
      />

      <Modal
        closeOnMaskClick={true}
        title={user.id === chatUid ? '编辑成员' : '查看成员'}
        visible={manageUserVisible}
        onClose={() => {
          setManageUserVisible(false)
        }}
        content={
          <div>
            <div style={{ fontSize: 10, color: 'var(--adm-color-weak)', margin: '0px 0 10px 0', textAlign: 'center' }}>
              <span>提示：群主左滑成员列表项可删除成员</span>
            </div>
            <List>
              {Object.keys(members || {}).map((key) => {
                const member = members[key]
                return (
                  <SwipeAction
                    key={member.id}
                    onAction={handleManageMember(member)}
                    rightActions={
                      user.id === chatUid
                        ? [
                            {
                              key: 'delete',
                              text: '删除',
                              color: 'danger',
                            },
                          ]
                        : []
                    }>
                    <List.Item
                      style={{ minWidth: '260px' }}
                      arrow={false}
                      key={member.id}
                      prefix={<AvatarAntD src={member.avatar} size={44} round="6px" />}
                      description={
                        <span>
                          {member.id === chatInfo.uid && <span style={{ color: 'red' }}>群主 </span>}
                          <span style={{ color: tailMap[member.status]?.color }}>
                            {tailMap[member.status]?.text || ''}
                          </span>
                        </span>
                      }>
                      {member.nickname}
                    </List.Item>
                  </SwipeAction>
                )
              })}
            </List>
          </div>
        }
      />

      <div onClick={onClickBack} style={{ width: '74px' }}>
        <Badge
          content={allUnreadMsgCount ? (allUnreadMsgCount > 99 ? '99+' : '' + allUnreadMsgCount) : null}
          style={{ '--right': '0%' }}>
          <Popover ref={newMsgRef} content="新消息" trigger="click" placement="bottom-start">
            <LeftSmall theme="filled" size="26" />
          </Popover>
        </Badge>
      </div>

      <div style={{ textAlign: 'center', height: '40px' }} onClick={openProfile('clickTitle')}>
        <span className="chat-title">{chatTitle}</span>
        <span style={{ display: 'block', fontSize: '11px' }}>
          {isShowMembers ? (
            <span style={{ color: '#646464' }}>
              {memberCount} 成员，<span style={{ color: '#38b503' }}>{onlineMemberCount} </span>在线
            </span>
          ) : (
            <span style={{ color: tailMap[chatStatus]?.color || '#a6a6a6' }}>{tailMap[chatStatus]?.text || ''}</span>
          )}
        </span>
      </div>

      <div style={{ width: '74px' }} onClick={openProfile('clickAvatar')}>
        {isGroupChat ? (
          <Avatar
            size="44px"
            name={chatTitle}
            round="6px"
            textSizeRatio={2}
            color={chatInfo.avatarColor}
            style={{ float: 'right' }}
          />
        ) : (
          <AvatarAntD style={{ float: 'right' }} src={avatarUrl || ''} size="44px" round="6px" />
        )}
      </div>
    </div>
  )
}
