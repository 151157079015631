import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { Button, Form, Input, Toast } from 'antd-mobile'
import { updateUserLikesApi } from '@/services/crm'

function UpdateLikes(props, ref) {
  const { chat } = props

  const [form] = Form.useForm()

  async function handleSave() {
    const toUid = chat.toUid
    const addLikes = form.getFieldValue('addLikes')
    const remark = form.getFieldValue('remark')

    const { code, msg } = await updateUserLikesApi(toUid, Number(addLikes), remark)
    if (code !== 200) {
      Toast.show({ icon: 'fail', content: msg })
      return
    }
    Toast.show('已完成')
    form.setFieldValue('addLikes', 1)
    form.setFieldValue('remark', '')
  }

  useImperativeHandle(ref, () => ({
    form,
  }))

  return (
    <div>
      <Form
        form={form}
        onFinish={handleSave}
        initialValues={{ addLikes: 1, remark: '' }}
        footer={
          <div style={{ display: 'flex', padding: 0 }}>
            <Button block type="submit" color="primary">
              确定
            </Button>
          </div>
        }>
        <Form.Item
          name="addLikes"
          label="add likes"
          rules={[
            {
              type: 'number',
              transform: (value) => {
                return Number(value)
              },
            },
          ]}>
          <Input placeholder="请输入数字" />
        </Form.Item>

        <Form.Item
          name="remark"
          label="备注"
          rules={[
            {
              type: 'string',
            },
          ]}>
          <Input placeholder="请输入" />
        </Form.Item>
      </Form>
    </div>
  )
}

export default forwardRef(UpdateLikes)
