import React, { forwardRef, useEffect, useImperativeHandle } from 'react'

import { Button, Form, Input, Toast } from 'antd-mobile'
import { setVipOrderApi } from '@/services/crm'

function SetVipOrder(props, ref) {
  const { chat } = props

  const [form] = Form.useForm()

  async function handleSave() {
    const toUid = chat.toUid
    const payType = form.getFieldValue('payType')
    const payFee = form.getFieldValue('payFee')
    const cycleTime = form.getFieldValue('cycleTime')
    const remark = form.getFieldValue('remark')

    const { code, msg } = await setVipOrderApi(toUid, payType, Number(payFee) * 100, cycleTime, remark)
    if (code !== 200) {
      Toast.show({ icon: 'fail', content: msg })
      return
    }
    Toast.show('已完成')
    form.setFieldValue('payType', '微信')
    form.setFieldValue('payFee', 5)
    form.setFieldValue('cycleTime', '2h')
    form.setFieldValue('remark', 'by manual')
    // 1小时 4元
    // 2小时 5元
    // 3小时 6元
    // 6小时 8元
    // 1天  11元
    // 2天  16元
    // 7天  39元
    // 30天 99元
  }

  useImperativeHandle(ref, () => ({
    form,
  }))

  return (
    <div>
      <Form
        form={form}
        onFinish={handleSave}
        initialValues={{ payType: '微信', payFee: 5, cycleTime: '2h', remark: 'by manual' }}
        footer={
          <div style={{ display: 'flex', padding: 0 }}>
            <Button block type="submit" color="primary">
              确定
            </Button>
          </div>
        }>
        <Form.Item name="payType" label="支付方式">
          <Input placeholder="请输入" />
        </Form.Item>

        <Form.Item
          name="payFee"
          label="支付金额（元）"
          rules={[
            {
              type: 'number',
              transform: (value) => {
                return Number(value)
              },
            },
          ]}>
          <Input placeholder="请输入数字" />
        </Form.Item>

        <Form.Item name="cycleTime" label="时长">
          <Input placeholder="1h 1d" />
        </Form.Item>
        <Form.Item
          name="remark"
          label="备注"
          rules={[
            {
              type: 'string',
            },
          ]}>
          <Input placeholder="请输入" />
        </Form.Item>
      </Form>
    </div>
  )
}

export default forwardRef(SetVipOrder)
