import React, { useEffect, useRef, useState } from 'react'
import { SmilingFace, ArrowCircleUp, Voice, Delete, AddOne } from '@icon-park/react'

import { Toast, SpinLoading, Dialog, Button, Popover } from 'antd-mobile'
import { getFitSize, isEnableMicrophone } from '@/utils/common'
import { maxMessageContentWidth, maxMessageContentHeight } from '@/const/config'
import { trackApi, uploadFileApi } from '@/services/base'
import { checkCanUploadFileApi, getDuanziApi } from '@/services/chat'
import { PictureOutline, MovieOutline, AppstoreOutline } from 'antd-mobile-icons'
import cookie from 'react-cookies'
import './Inputbox.css'
import RecordTimer from './RecordTimer'
// import AudioRecorder from 'audio-recorder-polyfill'
// window.MediaRecorder = AudioRecorder

const EmojiPickerButton = React.lazy(() => import('./EmojiPickerButton'))

export default function Inputbox(props) {
  const { onSendMsg, onInputMsg, chatid, toUid, checkCanSendFile, isGroupChat, isWaitGpt } = props
  const inputRef = React.createRef()
  const attachRef = React.createRef()
  const attachVideoRef = React.createRef()
  const recorderRef = useRef({ timer: null, startTime: null, recorder: null })

  const [loadingVisible, setLoadingVisible] = useState(false)
  const [micophoneEnable, setMicophoneEnable] = useState(false)
  const [micophoneVisible, setMicophoneVisible] = useState(true)
  const [recordTime, setRecordTime] = useState(null)
  const [mode, setMode] = useState('light')

  const initBottomHeight = navigator.standalone ? '10px' : '0px'
  const [bottomHeight, setBottomHeight] = React.useState(initBottomHeight)

  // 加载主题
  useEffect(() => {
    if (Number(cookie.load('isDark') || '0')) {
      setMode('dark')
    }
  }, [])

  useEffect(() => {
    setMicophoneEnable(true)
  }, [])

  const handleSubmit = () => {
    if (recorderRef.current.startTime) {
      if (new Date() - recorderRef.current.startTime < 1000) {
        Toast.show({ icon: 'fail', content: '录音时间过短' })
        handleDeleteVoice()
        return
      }
      stopRecordVoice()
      return
    }

    const el = inputRef.current
    if (!el.innerText || !el.innerText.trim()) {
      Toast.show({ icon: 'fail', content: '输入内容不能为空' })
      setMicophoneVisible(true)
      return
    }
    if (el.innerText.length > 500) {
      Toast.show({ icon: 'fail', content: '输入内容过多，请重新输入' })
      setMicophoneVisible(true)
      return
    }
    const result = onSendMsg('text', el.innerText, {})
    if (result) {
      el.innerText = null
      el.focus()
    }
    setMicophoneVisible(true)
  }

  async function startRecordVoice() {
    if (recorderRef.current.recorder) return

    let stream = null
    try {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      } else {
        // 浏览器不支持 MediaDevices API
        Dialog.alert({
          title: '录音出错了',
          content: '当前浏览器不支持录音，请使用Edge、Chrome、Safari浏览器发送语音',
        })
      }
    } catch (error) {
      if (error.toString().includes('Permission denied')) {
        Dialog.alert({
          title: '录音权限未开启',
          content: '您必须允许浏览器访问您的麦克风，然后才能发送语音。',
        })
      } else {
        Dialog.alert({
          title: '录音出错了',
          content: error.toString() + '。出现兼容性问题，请使用Edge、Chrome、Safari浏览器发送语音',
        })
      }
      console.log('startRecordVoice error：', error)
    }

    if (!stream) {
      stopRecordVoice(true)
      return
    }

    const constraints = { channelCount: 1, sampleRate: 48000 }
    const track = stream.getAudioTracks()[0]
    track.applyConstraints(constraints)
    const recorder = new MediaRecorder(stream)
    const chunks = []
    var dataType = null
    recorder.ondataavailable = (e) => {
      dataType = e.data.type
      chunks.push(e.data)
    }
    recorder.onstart = () => {}

    recorder.onstop = async () => {
      setRecordTime(null)
      const { cancelled } = recorderRef.current.recorder
      recorderRef.current.recorder = null
      if (cancelled) return
      var blob = new Blob(chunks, { type: dataType })
      const { code, data, msg } = await uploadFileApi('chataudio', blob, chatid)
      if (code !== 200) {
        Toast.show({ icon: 'fail', content: msg })
        return
      }
      const seconds = (new Date() - recorderRef.current.startTime) / 1000
      onSendMsg('audio', data.url, { seconds: seconds })
    }

    recorder.onerror = () => {
      setRecordTime(null)
    }

    recorderRef.current.recorder = recorder
    recorderRef.current.recorder.start(20)
    recorderRef.current.startTime = new Date()
    recorderRef.current.timer = setInterval(() => {
      const now = new Date()
      const diff = now - recorderRef.current.startTime
      // 最大录音时长
      if (diff > 60000) {
        Toast.show('最长录音60秒，已为您自动发送')
        handleSubmit()
      }
      setRecordTime(diff)
    }, 25)
  }

  function stopRecordVoice(cancelled = false) {
    setMicophoneVisible(true)
    setRecordTime(null)

    if (recorderRef.current.timer) {
      clearTimeout(recorderRef.current.timer)
      setRecordTime(null)
    }

    recorderRef.current.startTime = null
    if (!recorderRef.current.recorder) return
    recorderRef.current.recorder.cancelled = cancelled
    recorderRef.current.recorder.stop()
    recorderRef.current.recorder.stream.getAudioTracks().forEach((track) => track.stop())
  }

  function handleRecordVoice() {
    setMicophoneVisible(false)
    startRecordVoice()
  }

  function handleDeleteVoice() {
    stopRecordVoice(true)
  }

  const handleKeyDown = (event) => {
    if (event.code === 'Enter') {
      event.preventDefault()
      handleSubmit()
    }
  }

  const handlePaste = async (event) => {
    const { items } = event.clipboardData || event.originalEvent.clipboardData
    if (!items) return

    const files = []
    Array.from(items).forEach((item) => {
      if (item.kind.indexOf('file') === 0) {
        const file = item.getAsFile()
        if (file) {
          files.push(file)
        }
      }
    })

    if (files.length > 0 && files[0].type.indexOf('image/') !== -1) {
      event.preventDefault()
      // 判断是不是可以直接发图
      if (checkCanSendFile() === -1) {
        Toast.show({ icon: 'fail', content: '等聊热乎了再发文件吧~' })
        return
      }
      const resp = await checkCanUploadFileApi(toUid, 'chat', isGroupChat, chatid)
      if (resp.code !== 200) {
        Toast.show({ icon: 'fail', content: resp.msg })
        return
      }
      _uploadImageFile(files[0])
      return
    }
  }

  const handleInput = (event) => {
    onInputMsg()()
    const el = inputRef.current
    // 手机端删除到空的时候会莫名其妙出现换行符
    if (el.innerText.length === 1 && el.innerText === '\n') {
      el.innerText = ''
    }
    if (el.innerText && el.innerText !== '') {
      setMicophoneVisible(false)
    } else {
      setMicophoneVisible(true)
    }
  }

  const handleEmojiSelect = (e) => {
    const el = inputRef.current
    el.innerText += e.native
    setMicophoneVisible(false)
  }

  async function uploadImageFile() {
    const resp = await checkCanUploadFileApi(toUid, 'chat', isGroupChat, chatid)
    if (resp.code !== 200) {
      Toast.show({ icon: 'fail', content: resp.msg })
      return
    }
    const { files } = attachRef.current
    const tmpFile = files[0]
    _uploadImageFile(tmpFile)
  }

  async function _uploadImageFile(tmpFile) {
    const fileSize = tmpFile.size / 1024 / 1024
    const supportFileType = ['image/jpeg', 'image/png', 'image/gif']
    if (supportFileType.indexOf(tmpFile.type) === -1) {
      Toast.show({ icon: 'fail', content: '不支持该文件类型' })
      return
    }
    try {
      var fileUrl = null
      var fitSize = null
      var isSend = null
      if (tmpFile.type.indexOf('image/') !== -1) {
        if (fileSize > 10) {
          Toast.show({ icon: 'fail', content: '图片大小不能超过10MB' })
          return
        }
        setLoadingVisible(true)
        const { code, data, msg } = await uploadFileApi('chat', tmpFile, chatid)
        setLoadingVisible(false)
        if (code !== 200) {
          Toast.show({ icon: 'fail', content: msg })
          return
        }
        fileUrl = data.url
        const ret = getFitSize(data.meta.width, data.meta.height, maxMessageContentWidth, maxMessageContentHeight)
        fitSize = ret
        isSend = onSendMsg('image', data.url, ret)
      } else {
        Toast.show({ icon: 'fail', content: '不支持该文件类型' })
      }
    } catch (error) {
      const message = error?.message
      const track = error?.track
      // 打点上传错误信息
      await trackApi([
        {
          event: 'error',
          page: 'chat',
          widget: 'upload_image',
          extra: { message, track, fileUrl, fitSize, isSend, fileSize, fileType: tmpFile.type },
        },
      ])
      Toast.show({ icon: 'fail', content: '上传文件失败，请稍后重试' })
      setLoadingVisible(false)
    }
  }

  async function uploadVideoFile() {
    const resp = await checkCanUploadFileApi(toUid, 'chat', isGroupChat, chatid)
    if (resp.code !== 200) {
      Toast.show({ icon: 'fail', content: resp.msg })
      return
    }
    const { files } = attachVideoRef.current
    const tmpFile = files[0]
    _uploadVideoFile(tmpFile)
  }

  async function _uploadVideoFile(tmpFile) {
    const fileSize = tmpFile.size / 1024 / 1024
    const supportFileType = ['video/mp4', 'video/x-msvideo', 'video/quicktime']
    if (supportFileType.indexOf(tmpFile.type) === -1) {
      Toast.show({ icon: 'fail', content: '不支持该文件类型' })
      return
    }
    try {
      var fileUrl = null
      var fitSize = null
      var isSend = null
      if (tmpFile.type.indexOf('video/') !== -1) {
        if (fileSize > 50) {
          Toast.show({ icon: 'fail', content: '视频大小不能超过50MB' })
          return
        }
        // 增加loading
        setLoadingVisible(true)
        const { code, data, msg } = await uploadFileApi('chat', tmpFile, chatid)
        setLoadingVisible(false)
        if (code !== 200) {
          Toast.show({ icon: 'fail', content: msg })
          return
        }
        fileUrl = data.url
        const ret = getFitSize(data.meta.width, data.meta.height, maxMessageContentWidth, maxMessageContentHeight)
        fitSize = ret
        isSend = onSendMsg('video', data.url, ret)
      } else {
        Toast.show({ icon: 'fail', content: '不支持该文件类型' })
      }
    } catch (error) {
      const message = error?.message
      const track = error?.track
      // 打点上传错误信息
      await trackApi([
        {
          event: 'error',
          page: 'chat',
          widget: 'upload_video',
          extra: { message, track, fileUrl, fitSize, isSend, fileSize, fileType: tmpFile.type },
        },
      ])
      Toast.show({ icon: 'fail', content: '上传文件失败，请稍后重试' })
      setLoadingVisible(false)
    }
  }

  async function sendDuanzi() {
    const { code, data, msg } = await getDuanziApi()
    if (code !== 200) {
      Toast.show({ icon: 'fail', content: msg })
      return
    }
    const ret = getFitSize(data.width, data.height, maxMessageContentWidth, maxMessageContentHeight)
    onSendMsg(data.content_type, data.content, ret)
  }

  async function sendDice() {
    const val = Math.floor(Math.random() * 6) + 1
    onSendMsg('dice', val + '', {})
  }

  function handleAction(node) {
    if (node.key === 'send-image' || node.key === 'send-video') {
      // 聊天内容过少时，不允许发图片
      if (checkCanSendFile() === -1) {
        Toast.show({ icon: 'fail', content: '等聊热乎了再发文件吧~' })
        return
      } else if (checkCanSendFile() === -2) {
        Toast.show({ icon: 'fail', content: '公共聊天大厅禁止发送文件' })
        return
      }
    }

    if (node.key === 'send-image') {
      attachRef.current.click()
    } else if (node.key === 'send-video') {
      attachVideoRef.current.click()
    } else if (node.key === 'send-duanzi') {
      sendDuanzi()
    } else if (node.key === 'send-dice') {
      sendDice()
    }
  }

  return (
    <div className="inputbox-wrapper">
      <div className="inputbox">
        <div style={{ padding: 4, margin: '0px 3px' }}>
          <Popover.Menu
            placement="top-end"
            actions={[
              { key: 'send-dice', icon: <AppstoreOutline />, text: '掷骰子' },
              { key: 'send-image', icon: <PictureOutline />, text: '发送图片' },
              {
                key: 'send-video',
                icon: <MovieOutline />,
                text: '发送视频',
              },
              // { key: 'send-duanzi', icon: <SmileOutline />, text: '来个段子' },
            ]}
            mode={mode}
            onAction={handleAction}
            trigger="click">
            <AddOne theme="outline" size="24" fill="var(--adm-color-text)" />
          </Popover.Menu>
          <input style={{ display: 'none' }} ref={attachRef} type="file" accept="image/*" onChange={uploadImageFile} />
          <input
            style={{ display: 'none' }}
            ref={attachVideoRef}
            accept="video/*"
            type="file"
            onChange={uploadVideoFile}
          />
        </div>

        <div className="inputbox-bubble-wrapper">
          <div className="inputbox-bubble">
            <div className="inputbox-middle-column">
              <div
                id="inputbox-message"
                className="scrollbars-hidden"
                placeholder={recordTime ? '录音中...' : '善语结善缘，恶言伤人心~'}
                ref={inputRef}
                contentEditable
                suppressContentEditableWarning
                onKeyDown={handleKeyDown}
                onInput={handleInput}
                onPaste={handlePaste}
              />
            </div>
            {!recordTime && (
              <div className="inputbox-left-column" style={{ padding: 4 }}>
                <React.Suspense
                  fallback={
                    <div className="inputbox-icon-button" aria-label="Emoticon">
                      <SmilingFace size="24" />
                    </div>
                  }>
                  <EmojiPickerButton onSelect={handleEmojiSelect} />
                </React.Suspense>
              </div>
            )}

            <span style={{ lineHeight: '41px' }}>
              <RecordTimer time={recordTime} />
            </span>
          </div>
        </div>

        <div>
          {recordTime && (
            <span
              style={{ marginLeft: 4, marginRight: 4 }}
              className="inputbox-send-button inputbox-send-accent-button"
              onClick={handleDeleteVoice}>
              <div style={{ display: 'inline-block', marginLeft: 4 }}>
                <Delete theme="outline" size="30" fill="#ff0000" />
              </div>
            </span>
          )}

          {(!micophoneVisible || !micophoneEnable) && (
            <span
              style={{ marginLeft: 4, marginRight: 4 }}
              className="inputbox-send-button inputbox-send-accent-button"
              onClick={handleSubmit}>
              <div style={{ display: 'inline-block', marginBottom: 0, marginLeft: 4 }}>
                <ArrowCircleUp theme="filled" size="32" style={{ height: '32px' }} />
              </div>
            </span>
          )}

          {micophoneVisible && micophoneEnable && (
            <span
              style={{ marginLeft: 4, marginRight: 4 }}
              className="inputbox-send-button inputbox-send-accent-button"
              onClick={handleRecordVoice}>
              <div style={{ display: 'inline-block', marginBottom: 2, marginLeft: 4 }}>
                <Voice theme="outline" size="26" fill="var(--adm-color-text)" />
              </div>
            </span>
          )}
        </div>
      </div>

      <div style={{ height: bottomHeight, backgroundColor: 'var(--theme-window-bgcolor)' }} />
      <Dialog
        visible={loadingVisible}
        content={
          <div style={{ textAlign: 'center' }}>
            <SpinLoading style={{ '--size': '60px', margin: '0 auto', marginBottom: '20px' }} />
            {loadingVisible && '上传中'}
          </div>
        }
        closeOnMaskClick
        onClose={() => {
          setLoadingVisible(false)
        }}
        bodyStyle={{ opacity: 0.9 }}
      />
    </div>
  )
}
