import { trackApi } from '@/services/base'
import { DotLoading, SpinLoading } from 'antd-mobile'
import React, { useEffect, useState } from 'react'

const Tips = () => {
  // const [count, setCount] = useState(0)
  // useEffect(() => {
  //   const interval = window.setInterval(() => setCount((x) => x + 1), 10000)
  //   return () => window.clearInterval(interval)
  // }, [])

  const tips = [
    '长按消息可 复制、撤回 已发送的消息',
    '左滑聊天列表项可 收藏、清空记录、删除、拉黑 相应的聊天',
    '删除聊天，将清空双方历史聊天记录，并删除双方聊天入口',
    '清空私聊，将删除所有未收藏的私聊，已收藏的私聊需要手动删除',
    '清空私聊，将删除所有未收藏的私聊，已收藏的私聊需要手动删除，群聊不会被删除',
    // '聊天页面右上角 编辑 按钮可 创建群聊和清空私聊',
    '魅力值可通过 每日登录、积极聊天、他人点赞 累计',
    '点击设置页 联系客服 可随时与客服沟通',
    '上传的文件只保留半个小时，逾期将自动删除',
    '点击群聊头像可查看编辑群聊信息，点击群聊名称可查看编辑群成员',
    '私聊消息旁的 一个勾 表示消息发送成功，两个勾 表示对方已读',
    '聊天页面 触发浏览器后退 可回到上个页面',
    '请使用Edge、Chrome、Safari浏览器、微信内访问 获得更好的兼容性体验',
    '点击群聊邀请链接可加入对应的群聊',
  ]

  return (
    <div>
      <div style={{ fontSize: 10, color: 'var(--adm-color-weak)', marginTop: '10px' }}>
        <span>小提示: {tips[Math.floor(Math.random() * tips.length)]}</span>
      </div>
    </div>
  )
}

export default function LoadingContent(props) {
  const { page, widget, content, visible, ad } = props

  // useEffect(() => {
  //   if (visible) {
  //     trackApi([
  //       {
  //         event: 'expose',
  //         page: page,
  //         widget: widget,
  //       },
  //     ])
  //   }
  // }, [visible])

  return (
    <div style={{ textAlign: 'center' }}>
      {ad ? (
        <div style={{ width: '100%', height: '130px', background: '#e3e3e3', fontSize: 16, lineHeight: '130px' }}>
          广告位招租，请联系客服
        </div>
      ) : (
        <SpinLoading style={{ '--size': '60px', margin: '0 auto', marginBottom: '20px' }} />
      )}

      <div style={{ marginTop: '10px', fontSize: '18px' }}>
        {content}
        {ad && <DotLoading />}
      </div>
      <Tips />
    </div>
  )
}
